import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import InputShort from "../Components/InputShort";
import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import Icon from "../Assets/Icons/Icon";
import FontFaceObserver from "fontfaceobserver";

const PLSignUpLinked2 = observer(
  ({
    RegistrationTypeTitleData,
    companyName,
    setCompanyName,
    companyNameDone,
    setCompanyNameDone,
    representativeName,
    setRepresentativeName,
    representativeNameDone,
    setRepresentativeNameDone,
    sealImageSrc,
    setSealImageSrc,
    lisenceSrc,
    setLisenceSrc,
    lisenceDone,
    setLisenceDone,
    resistNumber,
    setResistNumber,
    resistNumberDone,
    setResistNumberDone,
    cleanedResistNumber,
    setCleanedResistNumber,
    sealImageSrcDone,
    foundationDay,
    setFoundationDay,
    foundationMonth,
    setFoundationMonth,
    foundationYear,
    setFoundationYear,
    foundationDate,
    setFoundationDate,
    setSealImageSrcDone,
    setSignin1,
    setSignin2,
    setSignin3,
  }) => {
    const [isMakeSeal, setIsMakeSeal] = useState(false);
    const [sealText, setSealText] = useState("");
    const [sealUrl, setSealUrl] = useState("");
    const canvasRef = useRef(null);
    const downloadLinkRef = useRef(null);
    const resistNumberData = {
      b_no: [cleanedResistNumber],
    };

    const onClickMakeSeal = async () => {
      setIsMakeSeal(true);
    };

    const onCloseMakeSeal = async () => {
      setIsMakeSeal(false);
    };

    const checkResistNumber = async (resistNumberData) => {
      try {
        const response = await fetch(
          "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=YvOh%2BAHuDPJt2pXE6UF1rahmToz%2BhKPxxls7KxGD7%2B9uaTe%2B%2BFZDy9EywpSbUA%2FtT3TLBChFoxWwlLWqQodeJw%3D%3D",
          {
            method: "POST",
            body: JSON.stringify(resistNumberData),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    useEffect(() => {
      const numberPattern = /^\d+$/;

      if (companyName.length > 0) {
        setCompanyNameDone(true);
      } else {
        setCompanyNameDone(false);
      }

      if (representativeName.length > 0) {
        setRepresentativeNameDone(true);
      } else {
        setRepresentativeNameDone(false);
      }

      if (lisenceSrc) {
        setLisenceDone(true);
      } else {
        setLisenceDone(false);
      }

      if (resistNumber.length > 0) {
        if (numberPattern.test(resistNumber)) {
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(resistNumber);
          setCleanedResistNumber(cleanedNumber);
          setResistNumberDone(true);
        } else {
          alert("숫자만 입력해주세요.");
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(resistNumber);
          setResistNumber(cleanedNumber);
          setResistNumberDone(false);
        }
      } else {
        setResistNumberDone(false);
      }

      if (sealImageSrc) {
        setSealImageSrcDone(true);
      } else {
        setSealImageSrcDone(false);
      }
    }, [
      companyName,
      representativeName,
      lisenceSrc,
      resistNumber,
      setCompanyNameDone,
      setRepresentativeNameDone,
      setLisenceDone,
      setCleanedResistNumber,
      setResistNumberDone,
      setResistNumber,
      sealImageSrc,
      setSealImageSrcDone,
    ]);

    const onSealUpload = (e) => {
      const file = e.target.files[0];
      const maxSize = 5242880;
      if (file && file.size > maxSize) {
        alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
        e.target.value = null;
      } else {
        setSealImageSrc(e.target.files[0]);
      }
    };

    const onLicenseUpload = (e) => {
      const file = e.target.files[0];
      const maxSize = 5242880;
      if (file && file.size > maxSize) {
        alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
        e.target.value = null;
      } else {
        setLisenceSrc(e.target.files[0]);
      }
    };

    const prevOnClick = () => {
      // amplitude.track("회원가입2 - 이메일/비밀번호 등록 페이지 진입");
      setSignin2(false);
      setSignin1(true);
    };

    const nextOnClick = async () => {
      const checkAllInformation = () => {
        if (
          checkRequiredCondition(companyName) &&
          checkRequiredCondition(representativeName) &&
          checkFoundationCondition() &&
          checkRequiredCondition(resistNumber) &&
          checkRequiredImageCondition(lisenceDone) &&
          checkRequiredImageCondition(sealImageSrcDone)
        ) {
          return true;
        } else {
          return false;
        }
      };

      if (!checkRequiredCondition(companyName)) {
        alert("기업/기관명을 입력해주세요.");
      } else if (!checkRequiredCondition(representativeName)) {
        alert("- 대표자명을 입력해주세요.");
      } else if (!checkFoundationCondition()) {
        alert("- 설립일을 입력해주세요.");
      } else if (!checkRequiredCondition(resistNumber)) {
        alert("- 사업자등록번호를 입력해주세요.");
      } else if (!checkRequiredImageCondition(lisenceDone)) {
        alert("- 사업자등록증 파일을 등록해주세요.");
      } else if (!checkRequiredImageCondition(sealImageSrcDone)) {
        alert("- 직인 이미지 파일을 등록해주세요.");
      } else if (checkAllInformation()) {
        const yearStr = String(foundationYear);
        const monthStr = String(foundationMonth).padStart(2, "0");
        const dayStr = String(foundationDay).padStart(2, "0");
        const updatedDateValue = `${yearStr}${monthStr}${dayStr}`;
        setFoundationDate(updatedDateValue);
        setSignin2(false);
        setSignin3(true);
      }
    };

    const checkFoundationCondition = () => {
      if (foundationYear && foundationMonth && foundationDay) {
        return true;
      } else {
        return false;
      }
    };
    const checkRequiredCondition = (val) => {
      if (val && val?.length >= 1) {
        return true;
      } else {
        return false;
      }
    };

    const checkRequiredImageCondition = (img) => {
      if (!img) {
        return false;
      }

      if (sealImageSrc && sealImageSrc.type && !sealImageSrc.type.startsWith("image/")) {
        return false;
      }

      return true;
    };

    const onCheckResistNum = async () => {
      const result = await checkResistNumber(resistNumberData);

      if (result.data[0].tax_type !== "국세청에 등록되지 않은 사업자등록번호입니다.") {
        setResistNumberDone(true);
      } else {
        alert(`[${resistNumber}] 는 국세청에 등록되지 않은 사업자등록번호입니다.\n사업자등록번호를 다시 한번 확인해주세요.`);
        setResistNumberDone(false);
      }
    };

    const checkAllValues =
      checkRequiredCondition(companyName) &&
      checkRequiredCondition(representativeName) &&
      checkFoundationCondition() &&
      checkRequiredCondition(resistNumber) &&
      checkRequiredImageCondition(lisenceDone) &&
      checkRequiredImageCondition(sealImageSrcDone)
        ? true
        : false;

    const generateSeal = () => {
      const specialCharRegex = /[^가-힣a-zA-Z0-9\s]/;
      if (sealText.length > 20) {
        alert("21글자 이상은 입력할 수 없습니다.");
        return;
      } else if (sealText.length === 0) {
        alert("직인에 들어갈 기업/기관명을 입력해주세요.");
        return;
      } else if (specialCharRegex.test(sealText)) {
        alert("특수문자 및 기호는 입력할 수 없습니다.");
        return;
      }

      const loadFonts = async () => {
        const kimjungchul = new FontFaceObserver("KimjungchulMyungjo-Bold");
        const hancom = new FontFaceObserver("HancomHoonminjeongeumH");
        await Promise.all([kimjungchul.load(), hancom.load()]);
      };

      loadFonts()
        .then(() => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");

          // Clear the canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw the outer circle
          ctx.beginPath();
          ctx.arc(150, 150, 140, 0, 2 * Math.PI);
          ctx.strokeStyle = "rgb(203, 25, 25)";
          ctx.lineWidth = 8;
          ctx.stroke();

          // Draw the inner circle
          ctx.beginPath();
          ctx.arc(150, 150, 80, 0, 2 * Math.PI);
          ctx.strokeStyle = "rgb(203, 25, 25)";
          ctx.lineWidth = 8;
          ctx.stroke();

          // Draw text in the inner circle (multiline text)
          ctx.font = "72px KimjungchulMyungjo-Bold"; // Font size and type for inner text
          ctx.fillStyle = "rgb(203, 25, 25)"; // Color for inner text
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText("대표", 150, 126); // First line
          ctx.fillText("이사", 150, 190); // Second line

          // Determine font size for outer text based on sealText length
          let outerFontSize = sealText.length >= 18 ? 42 : sealText.length >= 14 ? 48 : 54;
          ctx.font = `${outerFontSize}px HancomHoonminjeongeumH`; // Set font size

          // Draw the seal text around the outer circle
          const radius = 109;
          const angleStep = (2 * Math.PI) / (sealText.length + 1); // Adjust angle step to include ●

          ctx.fillStyle = "rgb(203, 25, 25)"; // Color for outer text
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          // Add the ● at the beginning of the text
          const fullText = `•${sealText}`;

          for (let i = 0; i < fullText.length; i++) {
            const angle = i * angleStep - Math.PI / 2;
            const x = 150 + radius * Math.cos(angle);
            const y = 150 + radius * Math.sin(angle);

            // Draw small circle at the position where the text starts
            if (fullText[i] === "•") {
              ctx.beginPath();
              ctx.arc(x, y, 8, 0, 2 * Math.PI); // 8px radius for the dot
              ctx.fillStyle = "rgb(203, 25, 25)"; // Same color as the text
              ctx.fill();
            } else {
              ctx.save();
              ctx.translate(x, y);
              ctx.rotate(angle + Math.PI / 2);
              ctx.fillText(fullText[i], 0, 0);
              ctx.restore();
            }
          }

          // Convert canvas to data URL and set sealUrl state
          const dataURL = canvas.toDataURL("image/png");
          setSealUrl(dataURL); // Update state with the data URL
          const downloadLink = downloadLinkRef.current;
          if (downloadLink) {
            downloadLink.href = dataURL;
            downloadLink.style.display = "inline";
          }
        })
        .catch((err) => {
          console.error("Font loading failed:", err);
          alert("서체 로딩에 실패했습니다. 다시 시도해주세요.");
        });
    };

    const handleDownloadSeal = () => {
      if (sealUrl.length > 0) {
        const link = document.createElement("a");
        link.href = sealUrl;
        link.download = `${sealText}_직인.png`;
        link.click();
        setSealText("");
        setSealUrl("");
        onCloseMakeSeal();
      } else {
        alert("직인 이미지를 생성해주세요.");
      }
    };

    return (
      <>
        {isMakeSeal && (
          <div className="CreateSeal_Modal_BG">
            <div className="CreateSeal_Modal_CT">
              <div className="CreateSeal_Title_CT">
                <div className="CreateSeal_Title_Copy">직인 생성</div>
                <button className="CreateSeal_Icon_CT" onClick={onCloseMakeSeal}>
                  <Icon name={"Close"} size={"24"} color={"#AAAAAA"} />
                </button>
              </div>
              <div className="CreateSeal_Divider" />
              <div>
                <div className="CreateSeal_Input_CT">
                  <input
                    type="text"
                    value={sealText}
                    onChange={(e) => setSealText(e.target.value)}
                    placeholder="직인에 사용될 기업/기관명을 입력해주세요."
                    className="CreateSeal_Input"
                  />
                  <button onClick={generateSeal} className="CreateSeal_GenerateButton">
                    생성
                  </button>
                </div>
                <div className="CreateSeal_Info_Copy">
                  기업/기관명은 20자까지 입력할 수 있습니다. (한글, 영문, 숫자만 입력 가능)
                  <br />
                  ※직인의 서체가 깨진다면 '생성' 버튼을 한번 더 클릭해주세요.
                </div>

                <div className="CreateSeal_Canvas_CT">
                  <canvas id="sealCanvas" ref={canvasRef} width="300" height="300" style={{ border: "1px solid rgb(170, 170, 170)" }}></canvas>
                </div>
                <div className="CreateSeal_Info_Copy">직인 다운로드를 하시면 직인 PNG 이미지가 다운로드됩니다.</div>
                <button ref={downloadLinkRef} className="CreateSeal_DownloadButton" onClick={handleDownloadSeal}>
                  직인 다운로드
                </button>
              </div>
            </div>
          </div>
        )}
        <RegistrationTypeTitle info={RegistrationTypeTitleData[1]} nextOnClick={nextOnClick} previousOnClick={prevOnClick} isProgressNext={checkAllValues} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "320px" }}>
          <InputShort
            required
            requiredCondition={companyNameDone}
            value={companyName}
            onChange={setCompanyName}
            label={"기업명"}
            placeholder={"사업자등록증에 기재된 기업명을 입력해주세요."}
          />

          <InputShort
            required
            requiredCondition={representativeNameDone}
            value={representativeName}
            onChange={setRepresentativeName}
            label={"대표자명"}
            placeholder={"사업자등록증에 기재된 대표자명을 입력해주세요."}
          />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
            <InputShort
              label={"설립일"}
              type={"date"}
              value={foundationDate}
              onChange={setFoundationDate}
              required
              requiredCondition={foundationYear.length === 4 && foundationMonth.length > 0 && foundationDay.length > 0}
              year={foundationYear}
              setYear={setFoundationYear}
              month={foundationMonth}
              setMonth={setFoundationMonth}
              day={foundationDay}
              setDay={setFoundationDay}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
            <InputShort
              label={"사업자 등록번호"}
              placeholder={"'-' 하이픈을 제외하고 입력해주세요."}
              value={resistNumber}
              onChange={setResistNumber}
              required
              requiredCondition={resistNumberDone}
              name={"companyName"}
              buttonLabel={"확인"}
              buttonStatus={resistNumberDone ? "disabled" : "CTA"}
              onClick={!resistNumberDone ? onCheckResistNum : null}
              readOnly={resistNumberDone}
              marginBottom={"8px"}
            />
            {resistNumberDone && <div className="Input_Feedback_Text_Confirm">사업자 등록번호가 유효합니다.</div>}
          </div>
          <InputShort
            label={"사업자 등록증"}
            placeholder={"pdf 파일을 업로드해주세요."}
            value={lisenceSrc?.name || ""}
            onChange={setLisenceSrc}
            required
            requiredCondition={lisenceSrc?.name?.length > 0}
            name={"lisence"}
            buttonLabel={"업로드"}
            onClick={null}
            onClickFile={onLicenseUpload}
            readOnly={true}
            type={"licenseFile"}
            marginTop={"24px"}
          />

          <InputShort
            label={"직인"}
            placeholder={"png 파일을 업로드해주세요."}
            value={sealImageSrc?.name || ""}
            onChange={setSealImageSrc}
            required
            requiredCondition={sealImageSrc?.name?.length > 0}
            name={"seal"}
            buttonLabel={"업로드"}
            onClick={null}
            onClickFile={onSealUpload}
            readOnly={true}
            type={"sealFile"}
            marginBottom={"16px"}
          />

          <button className="Create_Seal_Image_Button" onClick={onClickMakeSeal} style={{ marginBottom: "40px" }}>
            <div className="Create_Seal_Image_Button_Label_CT">
              <div className="Create_Seal_Image_Button_Copy">직인 파일이 없다면 직인 생성을 이용해보세요.</div>
              <div className="Create_Seal_Image_Button_Icon">
                <Icon name={"ArrowForward"} size={"16"} color={"#ffffff"} />
              </div>
            </div>
          </button>
        </div>
      </>
    );
  }
);

export default PLSignUpLinked2;
