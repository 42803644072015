import React from "react";
import Select from "react-dropdown-select";
import InputShort from "../Components/InputShort";
import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import Icon from "../Assets/Icons/Icon";
import { useDaumPostcodePopup } from "react-daum-postcode";

const SelectComponents = ({ arr, label, onChange, value }) => {
  return (
    <div className="ProfileDetail_Subject" style={{ marginBottom: "isVisibleEtc" ? 8 : null }}>
      <div className="Profile_Label_CT">
        <div
          className="Profile_Label_Copy"
          style={{
            fontSize: 14,
          }}
        >
          {label}
        </div>
        {/* <div className={businessTypeDone ? "Required_Dot_Blue" : "Required_Dot_Red"} /> */}
        <div className="Input_Label_Required_Dot" style={{ backgroundColor: value ? "#3769FC" : "#FF7373" }} />
      </div>
      <Select
        options={arr}
        onChange={(values) => onChange(values[0].value)}
        values={arr.filter((option) => option.value === value)}
        style={{
          width: 320,
          height: 36,
          borderRadius: 8,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#E3E3E3",
          paddingLeft: 8,
          paddingRight: 8,
          fontFamily: "Pretendard",
          fontWeight: 400,
          fontSize: 14,
          color: "rgb(70, 70, 70)",
        }}
      />
    </div>
  );
};

const PLLinkedWebDetails2 = ({
  RegistrationTypeTitleData,
  setSignin1,
  setSignin2,
  setSignin3,

  representativeName,
  setRepresentativeName,
  foundationDate,
  setFoundationDate,
  foundationDay,
  setFoundationDay,
  foundationMonth,
  setFoundationMonth,
  foundationYear,
  setFoundationYear,
  companyDivision,
  setCompanyDivision,
  capital,
  setCapital,
  investmentStage,
  setInvestmentStage,
  investmentAmount,
  setInvestmentAmount,
  address,
  setAddress,
  detailAddress,
  setDetailAddress,
}) => {
  const investmentPhaseOptions = [
    {
      value: "Seed",
      label: "Seed",
    },
    {
      value: "Pre A",
      label: "Pre A",
    },
    {
      value: "Series A",
      label: "Series A",
    },
    {
      value: "Pre B",
      label: "Pre B",
    },
    {
      value: "Series B",
      label: "Series B",
    },
    {
      value: "Pre C",
      label: "Pre C",
    },
    {
      value: "Series C",
      label: "Series C",
    },
    {
      value: "Pre D",
      label: "Pre D",
    },
    {
      value: "Series D",
      label: "Series D",
    },
  ];

  const corporateDivisionOptions = [
    {
      value: "중소기업",
      label: "중소기업",
    },
    {
      value: "중견기업",
      label: "중견기업",
    },
    {
      value: "대기업",
      label: "대기업",
    },
  ];
  const checkAllValues =
    representativeName && foundationYear && foundationMonth && foundationDay && companyDivision && capital && investmentStage && investmentAmount && address ? true : false;
  const nextOnClick = () => {
    if (checkAllValues) {
      const yearStr = String(foundationYear);
      const monthStr = String(foundationMonth).padStart(2, "0");
      const dayStr = String(foundationDay).padStart(2, "0");
      const updatedDateValue = `${yearStr}${monthStr}${dayStr}`;
      setFoundationDate(updatedDateValue);
      setSignin2(false);
      setSignin3(true);
    } else {
      alert("누락된 값이 있습니다. ");
    }
  };

  const prevOnClick = () => {
    setSignin2(false);
    setSignin1(true);
  };

  const checkRequiredCondition = (val) => {
    if (val.length >= 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkFoundationCondition = () => {
    if (foundationYear && foundationMonth && foundationDay) {
      return true;
    } else {
      return false;
    }
  };
  const open = useDaumPostcodePopup();

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddress(fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <>
      <RegistrationTypeTitle type={"integrate"} info={RegistrationTypeTitleData[1]} previousOnClick={prevOnClick} nextOnClick={nextOnClick} isProgressNext={checkAllValues} />

      <div>
        <InputShort
          required
          requiredCondition={checkRequiredCondition(representativeName)}
          value={representativeName}
          onChange={setRepresentativeName}
          label={"대표자명"}
          placeholder={"사업자등록증에 기재된 대표자명을 입력해주세요."}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
            justifyContent: "space-between",
            width: 230,
          }}
        >
          <InputShort
            required
            requiredCondition={checkFoundationCondition()}
            value={foundationDate}
            onChange={setFoundationDate}
            label={"설립일"}
            // dateInput={"year"}
            // dateLabel={"년"}
            // placeholder={"YYYY"}
            type={"date"}
            year={foundationYear}
            setYear={setFoundationYear}
            month={foundationMonth}
            setMonth={setFoundationMonth}
            day={foundationDay}
            setDay={setFoundationDay}
          />

          {/* <InputShort dateInput value={foundationMonth} onChange={setFoundationMonth} dateLabel={"월"} placeholder={"MM"} />
          <InputShort dateInput value={foundationDay} onChange={setFoundationDay} dateLabel={"일"} placeholder={"DD"} /> */}
        </div>
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <SelectComponents arr={corporateDivisionOptions} label={"기업구분"} onChange={setCompanyDivision} value={companyDivision} />
        </div>

        <InputShort
          required
          requiredCondition={checkRequiredCondition(capital)}
          value={capital}
          onChange={setCapital}
          label={"자본금"}
          placeholder={"자본금을 입력해주세요. (숫자만 입력)"}
        />
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <SelectComponents arr={investmentPhaseOptions} label={"투자단계"} value={investmentStage} onChange={setInvestmentStage} />
        </div>

        <InputShort
          required
          requiredCondition={checkRequiredCondition(investmentAmount)}
          value={investmentAmount}
          onChange={setInvestmentAmount}
          label={"투자액"}
          placeholder={"투자액을 입력해주세요. (숫자만 입력)"}
        />

        {/* <InputShort
          required
          requiredCondition={checkRequiredCondition(address)}
          label={"주소"}
          placeholder={"2줄 주소 컴포넌트 추가 예정."}
          value={address}
          onChange={setAddress}
        /> */}

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
          <div className="Input_Label_CT">
            <div className="Input_Label">주소</div>
            <div className="Input_Label_Required_Dot" style={{ backgroundColor: address.length > 0 ? "#3769FC" : "#FF7373" }} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "320px", marginBottom: "8px" }}>
            <input
              className="Input_Short_BG"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={"주소를 입력해주세요."}
              name={"address"}
              style={{ width: "270px" }}
            />
            <button style={{ margin: "0px", padding: "0px", backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }} onClick={handleClick}>
              <Icon name={"Search"} size={"24"} color={"#AAAAAA"} />
            </button>
          </div>
          <input
            className="Input_Short_BG"
            value={detailAddress}
            onChange={(e) => setDetailAddress(e.target.value)}
            placeholder={"상세 주소를 입력해주세요."}
            name={"detailAddress"}
          />
        </div>
      </div>
    </>
  );
};

export default PLLinkedWebDetails2;
