import React from "react";
import Icon from "../Assets/Icons/Icon";

const RegistrationTypeTitle = ({ type, info, previousOnClick, nextOnClick, isProgressNext, onClickForm }) => {
  const pageNumber = info.type === "integrate" ? `(${info.number}/3)` : `(${info.number}/5)`;
  const movementButtonText = info.type === "integrate" && info.number === "3" ? "완료" : info.type !== "integrate" && info.number === "5" ? "완료" : "다음";

  const title = (
    <div>
      <div
        style={{
          color: "#464646",
          fontFamily: "Pretendard",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 20,
          marginBottom: 12,
        }}
      >
        {type === "integrate" ? "써티드 연동" : "써티드 회원가입"}
      </div>
      <div
        style={{
          display: "flex",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#AAAAAA",
          width: 1520,
        }}
      />
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 40,
        marginBottom: 43,
      }}
    >
      <div>
        {title}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 22,
            fontFamily: "Pretendard",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 36,
            color: "#AAAAAA",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ color: "#464646" }}>{info.text}</div>
            <div
              style={{
                fontFamily: "Pretendard",
                fontStyle: "normal",
                fontWeight: 400,
                marginLeft: 10,
              }}
            >
              {pageNumber}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {info.number > 1 && (
              <div
                style={{
                  display: "flex",
                  color: "#3769FC",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={previousOnClick}
              >
                <Icon name={"ArrowBack"} size={"40"} color={"#3769FC"} />
                <div
                  style={{
                    fontFamily: "Pretendard",
                    fontStyle: "normal",
                    fontWeight: 400,
                  }}
                >
                  이전
                </div>
              </div>
            )}
            {onClickForm ? (
              <form
                style={{
                  display: "flex",
                  color: "#3769FC",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 47,
                  cursor: "pointer",
                }}
                onSubmit={onClickForm}
                encType="multipart/form-data"
              >
                <button
                  style={{
                    fontFamily: "Pretendard",
                    fontStyle: "normal",
                    fontSize: 36,
                    fontWeight: 400,
                    color: isProgressNext ? "#3769FC" : "#AAAAAA",
                    margin: 0,
                    padding: 0,
                    borderStyle: "none",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                  }}
                  type="submit"
                >
                  {movementButtonText}
                </button>
                <Icon name={"ArrowForward"} size={"40"} color={isProgressNext ? "#3769FC" : "#AAAAAA"} />
              </form>
            ) : (
              <button style={{ margin: 0, padding: 0, backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }} onClick={nextOnClick}>
                <div
                  style={{
                    display: "flex",
                    color: "#3769FC",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 47,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Pretendard",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "36px",
                      color: isProgressNext ? "#3769FC" : "#AAAAAA",
                    }}
                  >
                    {movementButtonText}
                  </div>
                  <Icon name={"ArrowForward"} size={"40"} color={isProgressNext ? "#3769FC" : "#AAAAAA"} />
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationTypeTitle;
