import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import axios from "axios";
import { requestListStore } from "../State/requestListStore";
import { useLocation } from "react-router-dom";
// import ChevronLeft from "../Assets/Icons/ChevronLeft";
// import ChevronRight from "../Assets/Icons/ChevronRight";
// import ReactPaginate from "react-paginate";
import Loading from "../Components/Loading";
// import RequestCard from "../Components/RequestCard";

const AD02RequestListMobile = observer(() => {
  const location = useLocation();
  const { companyId, companyPassword } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      const adminEndPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/admin";
      setIsLoading(true);
      const response = await axios.get(adminEndPoint, {
        withCredentials: true,
      });
      const requestListdata = response.data.data;
      if (Array.isArray(requestListdata)) {
        const modifiedArray = requestListdata?.map((item) => {
          if (item.LoseDate && item.LoseDate.length > 0) {
            const currentDate = new Date(item.LoseDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"));
            currentDate.setDate(currentDate.getDate() - 1);
            const modifiedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, "");
            return { ...item, LoseDate: modifiedDate };
          } else {
            return item;
          }
        });
        requestListStore.setRequestData(modifiedArray);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("AD02RequestListMobile - fetchData ERROR :", error);
    }
  };

  useEffect(() => {
    if (companyId === "certedadmin" && companyPassword === "20240101") {
      fetchData().catch((error) => {
        console.log("AD02RequestListMobile - fetchData ERROR :", error);
      });
    } else {
      return null;
    }
  }, [companyId, companyPassword]);
  // const requestListData = requestListStore.requestData;
  // const itemsPerPage = 6;
  // const [currentPage, setCurrentPage] = useState(0);
  // const handlePageChange = ({ selected }) => {
  //   setCurrentPage(selected);
  // };
  // const pageCount = Math.ceil(requestListData.length / itemsPerPage);

  // const getCurrentTestItems = () => {
  //   const startIndex = currentPage * itemsPerPage;
  //   const endIndex = startIndex + itemsPerPage;
  //   return requestListData.slice(startIndex, endIndex);
  // };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="AllContent_Between">
        {/* {getCurrentTestItems().map((item) => (
          <React.Fragment key={item.ID}>
            <RequestCard
              name={item.Name}
              date={item.IssuanceRequestDate}
              type={item.LoseDate.length > 0 ? "경력" : "재직"}
              companyName={item.IssuanceRequestCompanyName}
              representativeName={item?.RepresentativeName}
              email={item.Email}
              compEmail={item.IssuanceRequestCompanyEmail}
              telNumber={item.IssuanceRequestCompanyPhoneNumber}
              status={item.AuthStatus}
              seal={item?.Seal}
              resist={item?.BusinessRegistration}
              firmNm={item.FirmNm}
              position={item.Position}
              department={item.Department}
              jobPosition={item.JobPosition}
              jobList={item?.JobList}
              id={item.ID}
              acqDate={item.AcqDate}
              loseDate={item.LoseDate}
              rejectReason={item?.RejectionReason}
              uid={item.Uid}
              requestListData={requestListData}
              contactTime={item?.AdminContactTime || 0}
              note={item?.AdminNote || ""}
            />
          </React.Fragment>
        ))} */}
        {/* <div className="Wait_Pagination">
          <ReactPaginate
            previousLabel={<ChevronLeft />}
            previousClassName="Wait_Pagination_Style"
            nextLabel={<ChevronRight />}
            nextClassName="Wait_Pagination_Style"
            breakLabel="..."
            pageCount={pageCount}
            initialPage={0}
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="Wait_Pagination_Inactive_Copy"
            subContainerClassName="Wait_Pagination_Style"
            activeClassName="Wait_Pagination_Active_Copy"
            pageClassName="Wait_PaginationCT_Style"
          />
        </div> */}
      </div>
    </>
  );
});

export default AD02RequestListMobile;
