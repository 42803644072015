const Domain = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00024 19V5C2.00024 4.45 2.19608 3.97917 2.58774 3.5875C2.97941 3.19583 3.45024 3 4.00024 3H10.0002C10.5502 3 11.0211 3.19583 11.4127 3.5875C11.8044 3.97917 12.0002 4.45 12.0002 5V7H20.0002C20.5502 7 21.0211 7.19583 21.4127 7.5875C21.8044 7.97917 22.0002 8.45 22.0002 9V19C22.0002 19.55 21.8044 20.0208 21.4127 20.4125C21.0211 20.8042 20.5502 21 20.0002 21H4.00024C3.45024 21 2.97941 20.8042 2.58774 20.4125C2.19608 20.0208 2.00024 19.55 2.00024 19ZM4.00024 19H6.00024V17H4.00024V19ZM4.00024 15H6.00024V13H4.00024V15ZM4.00024 11H6.00024V9H4.00024V11ZM4.00024 7H6.00024V5H4.00024V7ZM8.00024 19H10.0002V17H8.00024V19ZM8.00024 15H10.0002V13H8.00024V15ZM8.00024 11H10.0002V9H8.00024V11ZM8.00024 7H10.0002V5H8.00024V7ZM12.0002 19H20.0002V9H12.0002V11H14.0002V13H12.0002V15H14.0002V17H12.0002V19ZM16.0002 13V11H18.0002V13H16.0002ZM16.0002 17V15H18.0002V17H16.0002Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default Domain;
