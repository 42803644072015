import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { observer } from "mobx-react";
import * as amplitude from "@amplitude/analytics-browser";
import Loading from "../Components/Loading";
import AlreadyConfirm from "../Assets/Icons/AlreadyConfirm";
import FolderDocument from "../Assets/Images/FolderDocument.png";
import Footer from "../Components/Footer";

const RQ01RequestIntroduction = observer(() => {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();
  const { token } = useParams();
  const [response, setResponse] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [hiqcData, setHiqcData] = useState(null);
  const [requestDataStatus, setRequestDataStatus] = useState(null);
  const [isBlock, setIsBlock] = useState(null);
  const [isExpired, setExpired] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const laodRequestData = async () => {
      const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/single-page";
      setIsLoading(true);
      const responseRequest = await axios.get(endPoint, {
        params: { token: token },
      });
      amplitude.init("9fa2d56b8be8c76c4e7a38a6bb76da6f", `${responseRequest.data.data.data.companyName}-${responseRequest.data.data.MemberData.Email}`, {
        defaultTracking: false,
        minIdLength: 1,
      });
      if (responseRequest.data.status === "증명서 인증 유효기간 만료" || responseRequest.data.status === "토큰 정보 확인 중 오류 발생") {
        amplitude.track("만료된 경력증명서 발급 요청 페이지 진입");
        setIsLoading(false);
        setExpired(true);
      } else {
        setResponse(responseRequest.data.data.data);
        setHiqcData(responseRequest.data.data.HIQCData);
        setMemberData(responseRequest.data.data.MemberData);

        if (responseRequest) {
          amplitude.track("경력 사항 안내 페이지 확인");
          const userEmail = responseRequest.data.data.MemberData.Email;
          const userId = responseRequest.data.data.MemberData.Uid;
          const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/certificate/hiqc";
          try {
            const responseHIQC = await axios.get(endPoint, {
              params: { email: userEmail, userId: userId },
            });

            if (responseHIQC) {
              const HIQCList = responseHIQC.data.EmploymentData;
              const targetID = responseRequest.data.data.HIQCData.ID;
              const targetObject = HIQCList.find((item) => item.ID === targetID);
              if (targetObject) {
                const authStatus = targetObject.AuthStatus;

                setRequestDataStatus(authStatus);
                if (
                  authStatus === "발급 승인" ||
                  authStatus === "발급 거절" ||
                  authStatus === "발급 완료" ||
                  authStatus === "직접 대기" ||
                  authStatus === "직접 지연" ||
                  authStatus === "직접 완료"
                ) {
                  setIsBlock(true);
                }

                setIsLoading(false);
              }
            }
          } catch (error) {
            console.log("AXIOS /certificate/hiqc error : ", error);
          }
        }
      }
    };

    if (!requestDataStatus && token) {
      laodRequestData();
    }
  }, [token, requestDataStatus]);

  return (
    <>
      {isLoading ? <Loading /> : null}

      {screenWidth > 1023 ? (
        <div className="AllContent_Between">
          {!isBlock && !isExpired ? (
            <div className="Email_CT">
              <div className="Email_LeftArea_CT">
                <div className="Email_LeftContents_CT">
                  <div className="Email_Main_Copy_CT">
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_1_Copy">안녕하세요.</div>
                    </div>
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_2_Copy">{memberData ? memberData.Name : ""}님</div>
                      <div className="Email_Main_1_Copy">의 {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"} 발급 요청을 위임받은</div>
                    </div>
                    <div className="Email_Main_1_Copy">주식회사 지디피스튜디오입니다.</div>
                  </div>
                  <div className="Email_Sub_Copy_CT">
                    <div className="Email_Sub_2_Copy">
                      전자증명서 발급 서비스를 제공하고 있는 <span style={{ fontWeight: 600 }}>주식회사 지디피스튜디오</span>
                      입니다.
                      <br />
                      <span style={{ fontWeight: 600 }}>{response?.name} 님</span>이 <span style={{ fontWeight: 600 }}>{response?.companyName}</span>
                      에게 {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"} 발급을 요청하였습니다. <br />
                      {response?.name} 님이 요청한 {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"}의 내용을 확인하시고
                      {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"}를 발급해주세요.
                    </div>
                    <button
                      className="Email_Button"
                      style={{ marginTop: "0px" }}
                      onClick={() => {
                        amplitude.track("경력 사항 확인 페이지 진입", {
                          유저: memberData?.name,
                          증명서종류: hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서",
                          직장명: response?.companyName,
                        });
                        navigate(`/email/${token}`, {
                          state: {
                            token: token,
                          },
                        });
                      }}
                    >
                      <div className="Email_Right_Button_BG" style={{ marginLeft: "0px", marginTop: "64px" }}>
                        내용 확인
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="Email_RightArea_Icon_CT">{screenWidth > 1329 ? <img className="Email_InfoIcon_CT" src={FolderDocument} alt="IconImage" /> : null}</div>
            </div>
          ) : isBlock ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리가 완료된 요청입니다.</div>
              </div>
            </div>
          ) : isExpired ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리 기한이 만료된 요청입니다.</div>
              </div>
            </div>
          ) : (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">유효하지 않은 요청입니다.</div>
              </div>
            </div>
          )}
          <div>
            <Footer />
          </div>
        </div>
      ) : (
        <div className="AllContent_Between_Detail ">
          {!isBlock && !isExpired ? (
            <div className="Email_CT">
              <div className="Email_LeftArea_CT">
                <div className="Email_LeftContents_CT">
                  <div className="Email_Main_Copy2_CT">
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_1_Copy">안녕하세요.</div>
                    </div>
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_2_Copy">{memberData ? memberData.Name : ""}님</div>
                      <div className="Email_Main_1_Copy">의 {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"} 발급 요청을 위임받은</div>
                    </div>
                    <div className="Email_Main_1_Copy">주식회사 지디피스튜디오입니다.</div>
                  </div>
                  <div className="Email_Sub_Copy_CT">
                    <div className="Email_Sub_2_Copy">
                      전자증명서 발급 서비스를 제공하고 있는 <span style={{ fontWeight: 600 }}>주식회사 지디피스튜디오</span>
                      입니다.
                      <br />
                      <span style={{ fontWeight: 600 }}>{response?.name} 님</span>이 <span style={{ fontWeight: 600 }}>{response?.companyName}</span>
                      에게 {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"} 발급을 요청하였습니다. <br />
                      {response?.name} 님이 요청한 {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"}의 내용을 확인하시고
                      {hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서"}를 발급해주세요.
                    </div>
                    <button
                      className="Email_Button"
                      style={{ marginTop: "0px" }}
                      onClick={() => {
                        amplitude.track("발급 진행 페이지 진입", {
                          유저: memberData?.name,
                          증명서종류: hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서",
                          직장명: response?.companyName,
                        });
                        navigate(`/email/${token}`, {
                          state: {
                            token: token,
                          },
                        });
                      }}
                    >
                      <div className="Email_Right_Button_BG" style={{ marginLeft: "0px", marginTop: "64px" }}>
                        내용 확인
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="Email_RightArea_CT"></div>
            </div>
          ) : isBlock ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리가 완료된 요청입니다.</div>
              </div>
            </div>
          ) : isExpired ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리 기한이 만료된 요청입니다.</div>
              </div>
            </div>
          ) : (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">유효하지 않은 요청입니다.</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default RQ01RequestIntroduction;
