import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import Select from "react-dropdown-select";
const { observer } = require("mobx-react");
const { useEffect } = require("react");
const { default: InputShort } = require("../Components/InputShort");

const PLSignUpLinked4 = observer(
  ({
    RegistrationTypeTitleData,
    setSignin3,
    setSignin4,
    setSignin5,
    managerNameDone,
    setManagerNameDone,
    managerTelNumber,
    managerName,
    setManagerTelNumber,
    setManagerName,
    managerTelNumberDone,
    setManagerTelNumberDone,
    setCleanedTelNumber,
    managerPosition,
    setManagerPosition,
    managerPositionDone,
    setManagerPositionDone,
    isVisibleManegerEtc,
    setIsVisibleManegerEtc,
    etcManagerPosition,
    setEtcManagerPosition,
  }) => {
    const positionOptions = [
      {
        value: "대표",
        label: "대표",
      },
      {
        value: "경영진",
        label: "경영진",
      },
      {
        value: "인사 팀장",
        label: "인사 팀장",
      },
      {
        value: "인사 팀원",
        label: "인사 팀원",
      },
      {
        value: "경영지원 팀장",
        label: "경영지원 팀장",
      },
      {
        value: "경영지원 팀원",
        label: "경영지원 팀원",
      },
      {
        value: "기타",
        label: "기타",
      },
    ];

    const prevOnClick = () => {
      // amplitude.track("회원가입4 - 기업/기관 직원수/업종 정보 등록 페이지 진입");
      setSignin4(false);
      setSignin3(true);
    };

    const nextOnClick = () => {
      if (!checkRequiredCondition(managerName)) {
        alert("- 담당자명을 입력해주세요.");
      } else if (!checkRequiredCondition(managerTelNumber)) {
        alert("- 담당자명 전화번호를 입력해주세요.");
      } else if (!checkRequiredCondition(managerPosition)) {
        alert("- 담당자 포지션을 입력해주세요.");
      } else if (checkRequiredCondition(managerName) && checkRequiredCondition(managerTelNumber) && managerPositionDone) {
        setSignin4(false);
        setSignin5(true);
      }
    };

    const checkRequiredCondition = (val) => {
      if (val?.length) {
        return true;
      } else {
        return false;
      }
    };

    useEffect(() => {
      const numberPattern = /^\d+$/;
      if (managerPosition.length > 0) {
        setManagerPositionDone(true);
      } else {
        setManagerPositionDone(false);
      }
      if (managerName.length > 0) {
        setManagerNameDone(true);
      } else {
        setManagerNameDone(false);
      }
      if (managerTelNumber.length > 0) {
        if (numberPattern.test(managerTelNumber)) {
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(managerTelNumber);
          setCleanedTelNumber(cleanedNumber);
          setManagerTelNumberDone(true);
        } else {
          alert("숫자만 입력해주세요.");
          const removeNonNumericCharacters = (str) => str.replace(/\D/g, "");
          const cleanedNumber = removeNonNumericCharacters(managerTelNumber);
          setManagerTelNumber(cleanedNumber);
          setManagerTelNumberDone(false);
        }
      } else {
        setManagerTelNumberDone(false);
      }

      if (managerPosition.length > 0 && managerPosition !== "기타") {
        setManagerPositionDone(true);
      } else if (managerPosition.length > 0 && managerPosition === "기타" && etcManagerPosition.length > 0) {
        setManagerPositionDone(true);
      } else {
        setManagerPositionDone(false);
      }

      if (managerPosition && managerPosition === "기타") {
        setIsVisibleManegerEtc(true);
      } else {
        setIsVisibleManegerEtc(false);
      }
    }, [
      managerPosition,
      managerName,
      managerTelNumber,
      etcManagerPosition,
      setManagerPositionDone,
      setManagerNameDone,
      setCleanedTelNumber,
      setManagerTelNumberDone,
      setManagerTelNumber,
      setIsVisibleManegerEtc,
    ]);

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "320px" }}>
        <RegistrationTypeTitle
          info={RegistrationTypeTitleData[3]}
          previousOnClick={prevOnClick}
          nextOnClick={nextOnClick}
          isProgressNext={managerNameDone && managerTelNumberDone && managerPosition}
        />

        <InputShort required requiredCondition={checkRequiredCondition(managerName)} value={managerName} onChange={setManagerName} label={"담당자명"} />
        <InputShort
          label={"담당자 전화번호"}
          placeholder={"담당자님의 전화번호를 입력해주세요. (숫자만 입력)"}
          value={managerTelNumber}
          onChange={setManagerTelNumber}
          required
          requiredCondition={managerTelNumberDone}
        />

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: isVisibleManegerEtc ? "8px" : "24px" }}>
          <div className="Input_Label_CT">
            <div className="Input_Label">담당자 포지션</div>
            <div className="Input_Label_Required_Dot" style={{ backgroundColor: managerPositionDone ? "#3769FC" : "#FF7373" }} />
          </div>
          <Select
            options={positionOptions}
            values={positionOptions.filter((option) => option.value === managerPosition)}
            onChange={(values) => setManagerPosition(values[0].value)}
            style={{
              width: 320,
              height: 36,
              borderRadius: 8,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "rgb(227, 227, 227)",
              paddingLeft: 8,
              paddingRight: 8,
              fontFamily: "Pretendard",
              fontWeight: 400,
              fontSize: 14,
              color: "rgb(70, 70, 70)",
            }}
          />
        </div>
        {isVisibleManegerEtc ? (
          <input
            className="Input_Short_BG"
            value={etcManagerPosition}
            onChange={(e) => setEtcManagerPosition(e.target.value)}
            placeholder="담당자 포지션을 직접 입력해주세요."
            name="etcManagerPosition"
            style={{
              marginBottom: 24,
            }}
            required
          />
        ) : null}
      </div>
    );
  }
);

export default PLSignUpLinked4;
