import { useState } from "react";
import { useLocation } from "react-router-dom";
import PLLinkedWebDetails1 from "../SubPages/PLLinkedWebDetails1";
import PLLinkedWebDetails2 from "../SubPages/PLLinkedWebDetails2";
import PLLinkedWebDetails3 from "../SubPages/PLLinkedWebDetails3";
import PLSignUpLinked1 from "../SubPages/PLSignUpLinked1";
import PLSignUpLinked2 from "../SubPages/PLSignUpLinked2";
import PLSignUpLinked3 from "../SubPages/PLSignUpLinked3";
import PLSignUpLinked4 from "../SubPages/PLSignUpLinked4";
import PLSignUpLinked5 from "../SubPages/PLSignUpLinked5";
import Loading from "../Components/Loading";
import IssueComplete from "../Assets/Images/IssueComplete";

const ProcessComplete = () => {
  const onClickLogin = () => {
    window.open("https://product.certed.co.kr", "_blank", "noopener noreferrer");
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", paddingTop: "48px", alignItems: "center" }}>
      <IssueComplete />

      <div style={{ color: "rgb(70, 70, 70)", fontFamily: "Pretendard", fontWeight: "400", fontSize: "24px", textAlign: "center" }}>써티드 투자사 연동이 완료되었습니다.</div>
      <button
        style={{
          marginTop: 32,
          backgroundColor: "#3769FC",
          fontSize: 24,
          fontWeight: 600,
          color: "#FFFFFF",
          width: 360,
          height: 72,
          borderRadius: 12,
          border: "none",
        }}
        onClick={onClickLogin}
      >
        로그인
      </button>
    </div>
  );
};

const PSRegisterProcess = () => {
  const location = useLocation();
  const { type, token } = location.state;

  // console.log("type: ", type);

  const nonMemberText = [
    {
      type: "signUp",
      text: "회원 기본 정보를 입력해주세요.",
      number: "1",
    },
    {
      type: "signUp",
      text: "기업 정보를 입력해주세요.",
      number: "2",
    },
    {
      type: "signUp",
      text: "기업 상세 정보를 입력해주세요.",
      number: "3",
    },
    {
      type: "signUp",
      text: "기업 담당자 정보를 입력해주세요.",
      number: "4",
    },
    {
      type: "signUp",
      text: "써티드 회원 가입 약관에 동의해주세요.",
      number: "5",
    },
  ];

  const memberText = [
    {
      type: "integrate",
      text: "써티드 회원 정보를 입력해주세요.",
      number: "1",
    },
    {
      type: "integrate",
      text: "기업 정보를 입력해주세요.",
      number: "2",
    },
    {
      type: "integrate",
      text: "써티드 투자사 연동 약관에 동의해주세요.",
      number: "3",
    },
  ];

  const [signin1, setSignin1] = useState(true);
  const [signin2, setSignin2] = useState(false);
  const [signin3, setSignin3] = useState(false);
  const [signin4, setSignin4] = useState(false);
  const [signin5, setSignin5] = useState(false);

  // 약관 useState
  const [isRadioAllCheck, setIsRadioAllCheck] = useState(false);
  const [isRadio1Check, setIsRadio1Check] = useState(false);
  const [isRadio2Check, setIsRadio2Check] = useState(false);
  const [isRadio3Check, setIsRadio3Check] = useState(false);
  const [isRadio4Check, setIsRadio4Check] = useState(false);
  const [isRadio5Check, setIsRadio5Check] = useState(false);
  const [termsDone, setTermsDone] = useState(false);

  // 써티드 플러스 약관 useState
  const [isPlusRadioAllCheck, setIsPlusRadioAllCheck] = useState(false);
  const [isPlusRadio1Check, setIsPlusRadio1Check] = useState(false);
  const [isPlusRadio2Check, setIsPlusRadio2Check] = useState(false);
  const [isPlusRadio3Check, setIsPlusRadio3Check] = useState(false);
  const [isPlusRadio4Check, setIsPlusRadio4Check] = useState(false);
  const [isPlusRadio5Check, setIsPlusRadio5Check] = useState(false);
  const [termsPlusDone, setTermsPlusDone] = useState(false);

  // 이메일 useState
  const [companyIdEmail, setCompanyIdEmail] = useState("");
  const [companyIdEmailNum, setCompanyIdEmailNum] = useState("");
  const [companyRandomNum, setCompanyRandomNum] = useState("");
  const [companyIdEmailNumDone, setCompanyIdEmailNumDone] = useState(false);
  const [isSendIdEmailNum, setIsSendIdEmailNum] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [clickEmailValid, setClickEmailValid] = useState(false);

  // 비밀번호 useState
  const [companyPassword, setCompanyPassword] = useState("");
  const [companyPasswordCheck, setCompanyPasswordCheck] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);

  // 기업명 useState
  const [companyName, setCompanyName] = useState("");
  const [companyNameDone, setCompanyNameDone] = useState(false);

  // 대표자명 useState
  const [representativeName, setRepresentativeName] = useState("");
  const [representativeNameDone, setRepresentativeNameDone] = useState(false);

  // 직인 useState
  const [sealImageSrc, setSealImageSrc] = useState(null);
  const [sealImageSrcDone, setSealImageSrcDone] = useState(false);

  // 사업자등록증 useState
  const [lisenceSrc, setLisenceSrc] = useState(null);
  const [lisenceDone, setLisenceDone] = useState(false);

  // 사업자등록번호 useState
  const [resistNumber, setResistNumber] = useState("");
  const [resistNumberDone, setResistNumberDone] = useState(false);
  const [cleanedResistNumber, setCleanedResistNumber] = useState("");

  // 업종 useState
  const [businessType, setBusinessType] = useState("");
  const [businessTypeDone, setBusinessTypeDone] = useState(false);
  const [isVisibleEtc, setIsVisibleEtc] = useState(false);
  const [etcBusinessType, setEtcBusinessType] = useState("");

  // 직원수 useState
  const [employeesNumber, setEmployeesNumber] = useState("");
  const [employeesNumberDone, setEmployeesNumberDone] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  // 담당자 이름 useState
  const [managerName, setManagerName] = useState("");
  const [managerNameDone, setManagerNameDone] = useState(false);

  // 담당자 전화번호 useState
  const [managerTelNumber, setManagerTelNumber] = useState("");
  const [managerTelNumberDone, setManagerTelNumberDone] = useState(false);
  const [cleanedTelNumber, setCleanedTelNumber] = useState("");

  const [firstDigitsTelNumber, setFirstDigitsTelNumber] = useState("");
  const [middleDigitsTelNumber, setMiddleDigitsTelNumber] = useState("");
  const [lastDigitsTelNumber, setLastDigitsTelNumber] = useState("");

  // 담당자 포지션 useState
  const [managerPosition, setManagerPosition] = useState("");
  const [managerPositionDone, setManagerPositionDone] = useState(false);
  const [isVisibleManegerEtc, setIsVisibleManegerEtc] = useState(false);
  const [etcManagerPosition, setEtcManagerPosition] = useState("");

  // 설립일 useState 3개 필요.
  const [foundationDate, setFoundationDate] = useState("");
  const [foundationYear, setFoundationYear] = useState("");
  const [foundationMonth, setFoundationMonth] = useState("");
  const [foundationDay, setFoundationDay] = useState("");

  //기업 구분
  const [companyDivision, setCompanyDivision] = useState("");

  // 자본금
  const [capital, setCapital] = useState("");

  //투자 단계
  const [investmentStage, setInvestmentStage] = useState("");

  //투자액
  const [investmentAmount, setInvestmentAmount] = useState("");

  // 주소
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      {isLoading ? <Loading /> : null}
      {type === "member" && signin1 === true ? (
        <PLLinkedWebDetails1
          RegistrationTypeTitleData={memberText}
          setSignin1={setSignin1}
          setSignin2={setSignin2}
          companyIdEmail={companyIdEmail}
          setCompanyIdEmail={setCompanyIdEmail}
          companyPassword={companyPassword}
          setCompanyPassword={setCompanyPassword}
          setIsLoading={setIsLoading}
        />
      ) : type === "member" && signin2 === true ? (
        <PLLinkedWebDetails2
          RegistrationTypeTitleData={memberText}
          setSignin1={setSignin1}
          setSignin2={setSignin2}
          setSignin3={setSignin3}
          representativeName={representativeName}
          foundationDate={foundationDate}
          foundationYear={foundationYear}
          foundationMonth={foundationMonth}
          foundationDay={foundationDay}
          companyDivision={companyDivision}
          capital={capital}
          investmentStage={investmentStage}
          investmentAmount={investmentAmount}
          address={address}
          detailAddress={detailAddress}
          setFoundationDate={setFoundationDate}
          setFoundationYear={setFoundationYear}
          setRepresentativeName={setRepresentativeName}
          setFoundationDay={setFoundationDay}
          setFoundationMonth={setFoundationMonth}
          setCompanyDivision={setCompanyDivision}
          setInvestmentStage={setInvestmentStage}
          setInvestmentAmount={setInvestmentAmount}
          setAddress={setAddress}
          setDetailAddress={setDetailAddress}
          setCapital={setCapital}
        />
      ) : type === "member" && signin3 === true ? (
        <PLLinkedWebDetails3
          RegistrationTypeTitleData={memberText}
          isPlusRadioAllCheck={isPlusRadioAllCheck}
          setIsPlusRadioAllCheck={setIsPlusRadioAllCheck}
          isPlusRadio1Check={isPlusRadio1Check}
          setIsPlusRadio1Check={setIsPlusRadio1Check}
          isPlusRadio2Check={isPlusRadio2Check}
          setIsPlusRadio2Check={setIsPlusRadio2Check}
          isPlusRadio3Check={isPlusRadio3Check}
          setIsPlusRadio3Check={setIsPlusRadio3Check}
          isPlusRadio4Check={isPlusRadio4Check}
          setIsPlusRadio4Check={setIsPlusRadio4Check}
          isPlusRadio5Check={isPlusRadio5Check}
          setIsPlusRadio5Check={setIsPlusRadio5Check}
          termsPlusDone={termsPlusDone}
          setTermsPlusDone={setTermsPlusDone}
          setSignin2={setSignin2}
          setSignin3={setSignin3}
          setSignin4={setSignin4}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          companyIdEmail={companyIdEmail}
          companyPassword={companyPassword}
          foundationDate={foundationDate}
          companyDivision={companyDivision}
          capital={capital}
          investmentStage={investmentStage}
          investmentAmount={investmentAmount}
          address={address}
          detailAddress={detailAddress}
          token={token}
        />
      ) : type === "member" && signin4 === true ? (
        <ProcessComplete />
      ) : type === "nonMember" && signin1 === true ? (
        <PLSignUpLinked1
          RegistrationTypeTitleData={nonMemberText}
          companyIdEmail={companyIdEmail}
          companyIdEmailNum={companyIdEmailNum}
          companyRandomNum={companyRandomNum}
          companyIdEmailNumDone={companyIdEmailNumDone}
          isSendIdEmailNum={isSendIdEmailNum}
          remainingTime={remainingTime}
          companyPassword={companyPassword}
          companyPasswordCheck={companyPasswordCheck}
          passwordValid={passwordValid}
          passwordMatch={passwordMatch}
          clickEmailValid={clickEmailValid}
          setCompanyIdEmail={setCompanyIdEmail}
          setCompanyIdEmailNum={setCompanyIdEmailNum}
          setCompanyRandomNum={setCompanyRandomNum}
          setCompanyIdEmailNumDone={setCompanyIdEmailNumDone}
          setIsSendIdEmailNum={setIsSendIdEmailNum}
          setRemainingTime={setRemainingTime}
          setCompanyPassword={setCompanyPassword}
          setCompanyPasswordCheck={setCompanyPasswordCheck}
          setPasswordValid={setPasswordValid}
          setPasswordMatch={setPasswordMatch}
          setClickEmailValid={setClickEmailValid}
          setSignin1={setSignin1}
          setSignin2={setSignin2}
          setSignin3={setSignin3}
          setSignin5={setSignin5}
          setIsLoading={setIsLoading}
        />
      ) : type === "nonMember" && signin2 === true ? (
        <PLSignUpLinked2
          RegistrationTypeTitleData={nonMemberText}
          companyName={companyName}
          companyNameDone={companyNameDone}
          representativeName={representativeName}
          representativeNameDone={representativeNameDone}
          sealImageSrc={sealImageSrc}
          lisenceSrc={lisenceSrc}
          lisenceDone={lisenceDone}
          resistNumber={resistNumber}
          resistNumberDone={resistNumberDone}
          cleanedResistNumber={cleanedResistNumber}
          foundationDate={foundationDate}
          foundationYear={foundationYear}
          foundationMonth={foundationMonth}
          foundationDay={foundationDay}
          setFoundationDate={setFoundationDate}
          setFoundationDay={setFoundationDay}
          setFoundationMonth={setFoundationMonth}
          setFoundationYear={setFoundationYear}
          setCompanyName={setCompanyName}
          setCompanyNameDone={setCompanyNameDone}
          setRepresentativeName={setRepresentativeName}
          setRepresentativeNameDone={setRepresentativeNameDone}
          setSealImageSrc={setSealImageSrc}
          setLisenceSrc={setLisenceSrc}
          setLisenceDone={setLisenceDone}
          setResistNumber={setResistNumber}
          setResistNumberDone={setResistNumberDone}
          setCleanedResistNumber={setCleanedResistNumber}
          sealImageSrcDone={sealImageSrcDone}
          setSealImageSrcDone={setSealImageSrcDone}
          setSignin2={setSignin2}
          setSignin3={setSignin3}
          setSignin1={setSignin1}
        />
      ) : type === "nonMember" && signin3 === true ? (
        <PLSignUpLinked3
          RegistrationTypeTitleData={nonMemberText}
          businessType={businessType}
          setBusinessType={setBusinessType}
          businessTypeDone={businessTypeDone}
          setBusinessTypeDone={setBusinessTypeDone}
          isVisibleEtc={isVisibleEtc}
          setIsVisibleEtc={setIsVisibleEtc}
          etcBusinessType={etcBusinessType}
          setEtcBusinessType={setEtcBusinessType}
          employeesNumber={employeesNumber}
          setEmployeesNumber={setEmployeesNumber}
          employeesNumberDone={employeesNumberDone}
          setEmployeesNumberDone={setEmployeesNumberDone}
          setSignin2={setSignin2}
          setSignin3={setSignin3}
          setSignin4={setSignin4}
          setCompanyDivision={setCompanyDivision}
          companyDivision={companyDivision}
          capital={capital}
          setCapital={setCapital}
          investmentStage={investmentStage}
          setInvestmentStage={setInvestmentStage}
          investmentAmount={investmentAmount}
          setInvestmentAmount={setInvestmentAmount}
          address={address}
          setAddress={setAddress}
          detailAddress={detailAddress}
          setDetailAddress={setDetailAddress}
        />
      ) : type === "nonMember" && signin4 === true ? (
        <PLSignUpLinked4
          RegistrationTypeTitleData={nonMemberText}
          termsDone={termsDone}
          companyDivision={companyDivision}
          companyIdEmail={companyIdEmail}
          companyPasswordCheck={companyPasswordCheck}
          companyNameDone={companyNameDone}
          representativeNameDone={representativeNameDone}
          businessTypeDone={businessTypeDone}
          employeesNumber={employeesNumber}
          companyName={companyName}
          employeesNumberDone={employeesNumberDone}
          isRadio4Check={isRadio4Check}
          isRadio5Check={isRadio5Check}
          representativeName={representativeName}
          businessType={businessType}
          sealImageSrc={sealImageSrc}
          lisenceSrc={lisenceSrc}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          managerNameDone={managerNameDone}
          setManagerNameDone={setManagerNameDone}
          managerTelNumber={managerTelNumber}
          managerName={managerName}
          setManagerTelNumber={setManagerTelNumber}
          setManagerName={setManagerName}
          managerTelNumberDone={managerTelNumberDone}
          setManagerTelNumberDone={setManagerTelNumberDone}
          cleanedTelNumber={cleanedTelNumber}
          setCleanedTelNumber={setCleanedTelNumber}
          managerPosition={managerPosition}
          setManagerPosition={setManagerPosition}
          managerPositionDone={managerPositionDone}
          setManagerPositionDone={setManagerPositionDone}
          isVisibleManegerEtc={isVisibleManegerEtc}
          setIsVisibleManegerEtc={setIsVisibleManegerEtc}
          etcManagerPosition={etcManagerPosition}
          setEtcManagerPosition={setEtcManagerPosition}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          cleanedResistNumber={cleanedResistNumber}
          etcBusinessType={etcBusinessType}
          firstDigitsTelNumber={firstDigitsTelNumber}
          setFirstDigitsTelNumber={setFirstDigitsTelNumber}
          middleDigitsTelNumber={middleDigitsTelNumber}
          setMiddleDigitsTelNumber={setMiddleDigitsTelNumber}
          lastDigitsTelNumber={lastDigitsTelNumber}
          setLastDigitsTelNumber={setLastDigitsTelNumber}
          setSignin3={setSignin3}
          setSignin4={setSignin4}
          setSignin5={setSignin5}
        />
      ) : type === "nonMember" && signin5 === true ? (
        <PLSignUpLinked5
          RegistrationTypeTitleData={nonMemberText}
          isRadioAllCheck={isRadioAllCheck}
          setIsRadioAllCheck={setIsRadioAllCheck}
          isRadio1Check={isRadio1Check}
          setIsRadio1Check={setIsRadio1Check}
          isRadio2Check={isRadio2Check}
          setIsRadio2Check={setIsRadio2Check}
          isRadio3Check={isRadio3Check}
          setIsRadio3Check={setIsRadio3Check}
          isRadio4Check={isRadio4Check}
          setIsRadio4Check={setIsRadio4Check}
          isRadio5Check={isRadio5Check}
          setIsRadio5Check={setIsRadio5Check}
          termsDone={termsDone}
          setTermsDone={setTermsDone}
          isPlusRadioAllCheck={isPlusRadioAllCheck}
          setIsPlusRadioAllCheck={setIsPlusRadioAllCheck}
          isPlusRadio1Check={isPlusRadio1Check}
          setIsPlusRadio1Check={setIsPlusRadio1Check}
          isPlusRadio2Check={isPlusRadio2Check}
          setIsPlusRadio2Check={setIsPlusRadio2Check}
          isPlusRadio3Check={isPlusRadio3Check}
          setIsPlusRadio3Check={setIsPlusRadio3Check}
          isPlusRadio4Check={isPlusRadio4Check}
          setIsPlusRadio4Check={setIsPlusRadio4Check}
          isPlusRadio5Check={isPlusRadio5Check}
          setIsPlusRadio5Check={setIsPlusRadio5Check}
          termsPlusDone={termsPlusDone}
          setTermsPlusDone={setTermsPlusDone}
          setSignin5={setSignin5}
          setSignin4={setSignin4}
          setIsLoading={setIsLoading}
          companyIdEmail={companyIdEmail}
          companyPasswordCheck={companyPasswordCheck}
          companyName={companyName}
          companyNameDone={companyNameDone}
          representativeNameDone={representativeNameDone}
          representativeName={representativeName}
          sealImageSrc={sealImageSrc}
          employeesNumberDone={employeesNumberDone}
          businessTypeDone={businessTypeDone}
          managerName={managerName}
          managerTelNumber={managerTelNumber}
          managerPosition={managerPosition}
          cleanedResistNumber={cleanedResistNumber}
          employeesNumber={employeesNumber}
          etcBusinessType={etcBusinessType}
          businessType={businessType}
          etcManagerPosition={etcManagerPosition}
          lisenceSrc={lisenceSrc}
          foundationDate={foundationDate}
          companyDivision={companyDivision}
          capital={capital}
          investmentStage={investmentStage}
          investmentAmount={investmentAmount}
          address={address}
          detailAddress={detailAddress}
          token={token}
        />
      ) : (
        <ProcessComplete />
      )}
    </>
  );
};

export default PSRegisterProcess;
