import axios from "axios";
import { observer } from "mobx-react";
import { useEffect } from "react";
import InputShort from "../Components/InputShort";
import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import { debounce } from "lodash";

const PLSignUpLinked1 = observer(
  ({
    companyIdEmail,
    setCompanyIdEmail,
    companyIdEmailNum,
    setCompanyIdEmailNum,
    companyRandomNum,
    setCompanyRandomNum,
    companyIdEmailNumDone,
    setCompanyIdEmailNumDone,
    isSendIdEmailNum,
    setIsSendIdEmailNum,
    remainingTime,
    setRemainingTime,
    companyPassword,
    setCompanyPassword,
    companyPasswordCheck,
    setCompanyPasswordCheck,
    passwordValid,
    setPasswordValid,
    passwordMatch,
    setPasswordMatch,
    clickEmailValid,
    setClickEmailValid,
    setSignin1,
    setSignin2,
    RegistrationTypeTitleData,
    setIsLoading,
  }) => {
    const isEmailValid = (email) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    };

    const onSendIdEmailNum = async () => {
      if (isEmailValid(companyIdEmail)) {
        setIsLoading(true);
        setIsSendIdEmailNum(true);
        setClickEmailValid(false);
        try {
          const endPoint = `https://t19yskdet3.execute-api.ap-northeast-2.amazonaws.com/product/member/email`;
          const response = await axios.get(endPoint, {
            params: { email: companyIdEmail },
          });
          setCompanyRandomNum(response.data.code);
          setIsLoading(false);
          alert(`${companyIdEmail} 주소로 인증번호를 발송했습니다.`);
        } catch (error) {
          setIsLoading(false);
          alert(`${companyIdEmail} 주소로 인증번호를 발송을 실패했습니다.\n다시 한번 시도해주세요.`);
          console.log("Send email number error", error);
        }
      } else {
        alert("이메일 주소가 유효하지 않습니다.");
      }
    };

    const debouncedOnSendIdEmailNum = debounce(onSendIdEmailNum, 500);

    const onResendIdEmailNum = () => {
      if (isSendIdEmailNum) {
        setClickEmailValid(false);
        debouncedOnSendIdEmailNum();
        setRemainingTime(180);
      }
    };
    const debouncedOnResendIdEmailNum = debounce(onResendIdEmailNum, 500);

    const onCheckIdEmailNum = () => {
      setClickEmailValid(true);
      if (companyRandomNum === companyIdEmailNum) {
        setCompanyIdEmailNumDone(true);
      } else {
        setCompanyIdEmailNumDone(false);
      }
    };

    useEffect(() => {
      const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?^()&])[A-Za-z\d@$!%*^()#?&]{8,}$/;
      const isPasswordValid = passwordPattern.test(companyPassword);
      setPasswordValid(isPasswordValid);
      if (isPasswordValid && companyPassword === companyPasswordCheck) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }, [companyPassword, companyPasswordCheck, setPasswordMatch, setPasswordValid]);

    useEffect(() => {
      let timerInterval;

      if (!companyIdEmailNumDone && isSendIdEmailNum) {
        timerInterval = setInterval(() => {
          if (remainingTime > 0) {
            setRemainingTime((prevTime) => prevTime - 1);
          }
        }, 1000); // 1초마다 감소
      } else {
        clearInterval(timerInterval);
      }
      return () => clearInterval(timerInterval);
    }, [remainingTime, companyIdEmailNumDone, isSendIdEmailNum, setRemainingTime]);

    const nextOnClick = () => {
      if (companyIdEmail.length < 1) {
        alert("아이디를 입력해주세요.");
      } else if (companyIdEmailNum.length < 1) {
        alert("인증번호를 입력해주세요");
      } else if (companyPassword.length < 1) {
        alert("비밀번호를 설정해주세요.");
      } else if (companyPasswordCheck.length < 1) {
        alert("비밀번호를 다시 입력해주세요");
      } else if (
        companyIdEmail?.length > 0 &&
        companyIdEmailNum?.length > 0 &&
        companyRandomNum === companyIdEmailNum &&
        companyIdEmailNumDone &&
        companyPassword?.length > 0 &&
        companyPasswordCheck?.length > 0 &&
        companyPassword === companyPasswordCheck &&
        passwordMatch
      ) {
        setSignin1(false);
        setSignin2(true);
      } else {
        alert("누락된 값을 입력해주세요.");
      }
    };

    const emailValidComplete = () => {
      return remainingTime > 0 && companyIdEmailNumDone;
    };

    return (
      <>
        <div>
          <RegistrationTypeTitle info={RegistrationTypeTitleData[0]} nextOnClick={nextOnClick} isProgressNext={companyIdEmailNumDone && passwordMatch} />
          <div
            className="Leave_InputField_CT"
            style={{
              alignItems: "center",
              maxWidth: 1520,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <InputShort
                label={"아이디"}
                placeholder={"이메일 형식의 아이디를 입력해주세요."}
                value={companyIdEmail}
                onChange={setCompanyIdEmail}
                required
                requiredCondition={companyIdEmailNumDone}
                buttonLabel={isSendIdEmailNum ? "재발송" : "인증 발송"}
                onClick={isSendIdEmailNum && !companyIdEmailNumDone ? debouncedOnResendIdEmailNum : !isSendIdEmailNum && !companyIdEmailNumDone ? debouncedOnSendIdEmailNum : null}
                name={"email"}
                readOnly={(remainingTime > 0 && companyIdEmailNumDone) || null}
                buttonStatus={companyIdEmailNumDone ? "disabled" : "CTA"}
                marginBottom={"8px"}
              />
              {isSendIdEmailNum && (
                <>
                  <InputShort
                    placeholder={"인증번호를 입력해주세요."}
                    value={companyIdEmailNum}
                    onChange={setCompanyIdEmailNum}
                    buttonLabel={"확인"}
                    onClick={!companyIdEmailNumDone ? onCheckIdEmailNum : null}
                    name={"emailValidNumber"}
                    readOnly={(remainingTime > 0 && companyIdEmailNumDone) || null}
                    buttonStatus={companyIdEmailNumDone ? "disabled" : "CTA"}
                    marginBottom={"8px"}
                  />
                  {remainingTime > 0 ? (
                    emailValidComplete() ? (
                      <div className="Input_Feedback_Text_Confirm">인증이 완료되었습니다.</div>
                    ) : clickEmailValid ? (
                      <div className="Input_Feedback_Text_Error">
                        {Math.floor(remainingTime / 60)}분 {remainingTime % 60}초 | 인증번호가 일치하지 않습니다.
                      </div>
                    ) : (
                      <div className="Input_Feedback_Text_Error">
                        {Math.floor(remainingTime / 60)}분 {remainingTime % 60}초
                      </div>
                    )
                  ) : (
                    <div className="Input_Feedback_Text_Error">인증시간이 만료되었습니다.</div>
                  )}
                </>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <InputShort
                label={"비밀번호"}
                placeholder={"영문, 숫자, 특수문자를 조합하여 8자 이상 20자 이하"}
                value={companyPassword}
                onChange={setCompanyPassword}
                required
                requiredCondition={passwordMatch}
                name={"password"}
                marginTop={"24px"}
                marginBottom={"8px"}
                type={"password"}
              />
              <InputShort
                placeholder={"비밀번호를 한번 더 입력해주세요."}
                value={companyPasswordCheck}
                onChange={setCompanyPasswordCheck}
                name={"passwordMatch"}
                marginBottom={"8px"}
                type={"password"}
              />
              {passwordMatch ? (
                <div className="Input_Feedback_Text_Confirm">비밀번호가 일치합니다.</div>
              ) : (companyPasswordCheck.length > 0) & !passwordMatch ? (
                <div className="Input_Feedback_Text_Error">비밀번호가 불일치합니다.</div>
              ) : null}
              {!passwordValid && companyPassword.length > 0 && <div className="Input_Feedback_Text_Error">8자 이상 20자 이하 / 영문, 숫자, 특수문자 포함</div>}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default PLSignUpLinked1;
