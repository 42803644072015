const BarChart = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 20C16.7167 20 16.4792 19.9042 16.2875 19.7125C16.0958 19.5208 16 19.2833 16 19V14C16 13.7167 16.0958 13.4792 16.2875 13.2875C16.4792 13.0958 16.7167 13 17 13H19C19.2833 13 19.5208 13.0958 19.7125 13.2875C19.9042 13.4792 20 13.7167 20 14V19C20 19.2833 19.9042 19.5208 19.7125 19.7125C19.5208 19.9042 19.2833 20 19 20H17ZM11 20C10.7167 20 10.4792 19.9042 10.2875 19.7125C10.0958 19.5208 10 19.2833 10 19V5C10 4.71667 10.0958 4.47917 10.2875 4.2875C10.4792 4.09583 10.7167 4 11 4H13C13.2833 4 13.5208 4.09583 13.7125 4.2875C13.9042 4.47917 14 4.71667 14 5V19C14 19.2833 13.9042 19.5208 13.7125 19.7125C13.5208 19.9042 13.2833 20 13 20H11ZM5 20C4.71667 20 4.47917 19.9042 4.2875 19.7125C4.09583 19.5208 4 19.2833 4 19V10C4 9.71667 4.09583 9.47917 4.2875 9.2875C4.47917 9.09583 4.71667 9 5 9H7C7.28333 9 7.52083 9.09583 7.7125 9.2875C7.90417 9.47917 8 9.71667 8 10V19C8 19.2833 7.90417 19.5208 7.7125 19.7125C7.52083 19.9042 7.28333 20 7 20H5Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default BarChart;
