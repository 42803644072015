import Select from "react-dropdown-select";
import InputShort from "../Components/InputShort";
import { observer } from "mobx-react";
import { useEffect } from "react";
import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import { useDaumPostcodePopup } from "react-daum-postcode";
import Icon from "../Assets/Icons/Icon";

const PLSignUpLinked3 = observer(
  ({
    RegistrationTypeTitleData,
    businessType,
    companyDivision,
    setCompanyDivision,
    setBusinessType,
    businessTypeDone,
    setBusinessTypeDone,
    isVisibleEtc,
    setIsVisibleEtc,
    etcBusinessType,
    setEtcBusinessType,
    employeesNumber,
    setEmployeesNumber,
    employeesNumberDone,
    setEmployeesNumberDone,
    setSignin2,
    setSignin3,
    setSignin4,
    capital,
    setCapital,
    investmentStage,
    setInvestmentStage,
    investmentAmount,
    setInvestmentAmount,
    address,
    setAddress,
    detailAddress,
    setDetailAddress,
  }) => {
    const open = useDaumPostcodePopup();

    const numberOptions = [
      {
        value: "1~4 명",
        label: "1~4 명",
      },
      {
        value: "5~10 명",
        label: "5~10 명",
      },
      {
        value: "11~20 명",
        label: "11~20 명",
      },
      {
        value: "21~49 명",
        label: "21~49 명",
      },
      {
        value: "50~99 명",
        label: "50~99 명",
      },
      {
        value: "100~299 명",
        label: "100~299 명",
      },
      {
        value: "300 명 이상",
        label: "300 명 이상",
      },
    ];

    const typeOptions = [
      {
        value: "제조업",
        label: "제조업",
      },
      {
        value: "정보통신업 (IT)",
        label: "정보통신업 (IT)",
      },
      {
        value: "F&B, 프랜차이즈",
        label: "F&B, 프랜차이즈",
      },
      {
        value: "도・소매 유통 판매",
        label: "도・소매 유통 판매",
      },
      {
        value: "엔터테인먼트, 여행, 예술",
        label: "엔터테인먼트, 여행, 예술",
      },
      {
        value: "공공 행정",
        label: "공공 행정",
      },
      {
        value: "건설 및 기간 산업",
        label: "건설 및 기간 산업",
      },
      {
        value: "서비스",
        label: "서비스",
      },
      {
        value: "세무, 법무, 노무",
        label: "세무, 법무, 노무",
      },
      {
        value: "의료 보건, 사회 복지",
        label: "의료 보건, 사회 복지",
      },
      {
        value: "금융, 보험, 부동산",
        label: "금융, 보험, 부동산",
      },
      {
        value: "협회 및 단체",
        label: "협회 및 단체",
      },
      {
        value: "교육 및 연구",
        label: "교육 및 연구",
      },
      {
        value: "1차 산업 (농・임・수산업)",
        label: "1차 산업 (농・임・수산업)",
      },
      {
        value: "기타",
        label: "기타",
      },
    ];

    const investmentPhaseOptions = [
      {
        value: "Seed",
        label: "Seed",
      },
      {
        value: "Pre A",
        label: "Pre A",
      },
      {
        value: "Series A",
        label: "Series A",
      },
      {
        value: "Pre B",
        label: "Pre B",
      },
      {
        value: "Series B",
        label: "Series B",
      },
      {
        value: "Pre C",
        label: "Pre C",
      },
      {
        value: "Series C",
        label: "Series C",
      },
      {
        value: "Pre D",
        label: "Pre D",
      },
      {
        value: "Series D",
        label: "Series D",
      },
    ];

    const corporateDivisionOptions = [
      {
        value: "중소기업",
        label: "중소기업",
      },
      {
        value: "중견기업",
        label: "중견기업",
      },
      {
        value: "대기업",
        label: "대기업",
      },
    ];

    const handleComplete = (data) => {
      let fullAddress = data.address;
      let extraAddress = "";

      if (data.addressType === "R") {
        if (data.bname !== "") {
          extraAddress += data.bname;
        }
        if (data.buildingName !== "") {
          extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
      }
      setAddress(fullAddress);
    };

    const handleClick = () => {
      open({ onComplete: handleComplete });
    };

    const prevOnClick = () => {
      // amplitude.track("회원가입3 - 기업/기관 필수 정보 등록 페이지 진입");
      setSignin3(false);
      setSignin2(true);
    };

    const nextOnClick = () => {
      const checkAllCondition = () => {
        if (
          checkRequiredCondition(businessType) &&
          employeesNumberDone &&
          companyDivision &&
          checkRequiredCondition(capital) &&
          investmentStage.length > 0 &&
          checkRequiredCondition(investmentAmount) &&
          address.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      };

      if (!checkRequiredCondition(businessType)) {
        alert("- 업종을 입력해주세요.");
      } else if (!companyDivision) {
        alert("- 기업구분을 선택해주세요.");
      } else if (!checkRequiredCondition(capital)) {
        alert("- 자본금을 입력해주세요.");
      } else if (!investmentStage) {
        alert("- 투자단계을 선택해주세요.");
      } else if (!checkRequiredCondition(investmentAmount)) {
        alert("- 투자액을 입력해주세요.");
      } else if (!address) {
        alert("- 주소를 입력해주세요.");
      } else if (checkAllCondition()) {
        setSignin3(false);
        setSignin4(true);
      }
    };

    const checkRequiredCondition = (val) => {
      if (val?.length >= 1) {
        return true;
      } else {
        return false;
      }
    };

    useEffect(() => {
      if (employeesNumber.length > 0) {
        setEmployeesNumberDone(true);
      } else {
        setEmployeesNumberDone(false);
      }

      if (businessType.length > 0 && businessType !== "기타") {
        setBusinessTypeDone(true);
      } else if (businessType.length > 0 && businessType === "기타" && etcBusinessType.length > 0) {
        setBusinessTypeDone(true);
      } else {
        setBusinessTypeDone(false);
      }

      if (businessType === "기타") {
        setIsVisibleEtc(true);
      } else {
        setIsVisibleEtc(false);
      }
    }, [employeesNumber, businessType, etcBusinessType, setEmployeesNumberDone, setBusinessTypeDone, setIsVisibleEtc]);

    const isNumericString = (str) => {
      return /^\d+$/.test(str);
    };

    return (
      <>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "320px" }}>
          <RegistrationTypeTitle
            info={RegistrationTypeTitleData[2]}
            nextOnClick={nextOnClick}
            previousOnClick={prevOnClick}
            isProgressNext={
              checkRequiredCondition(businessType) &&
              employeesNumberDone &&
              companyDivision &&
              checkRequiredCondition(capital) &&
              investmentStage.length > 0 &&
              checkRequiredCondition(investmentAmount) &&
              address.length > 0
            }
          />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: isVisibleEtc ? "8px" : "24px" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">업종</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: businessTypeDone ? "#3769FC" : "#FF7373" }} />
            </div>
            <Select
              options={typeOptions}
              values={typeOptions.filter((option) => option.value === businessType)}
              onChange={(values) => setBusinessType(values[0].value)}
              style={{
                width: 320,
                height: 36,
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgb(227, 227, 227)",
                paddingLeft: 8,
                paddingRight: 8,
                fontFamily: "Pretendard",
                fontWeight: 400,
                fontSize: 14,
                color: "rgb(70, 70, 70)",
              }}
            />
          </div>
          {isVisibleEtc ? (
            <input
              className="Input_Short_BG"
              value={etcBusinessType}
              onChange={(e) => setEtcBusinessType(e.target.value)}
              placeholder="업종을 직접 입력해주세요."
              name="etcBusinessType"
              style={{
                // borderColor: "rgb(170, 170, 170)",
                marginBottom: 24,
              }}
              required
            />
          ) : null}

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginBottom: "24px" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">직원수</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: employeesNumberDone > 0 ? "#3769FC" : "#FF7373" }} />
            </div>
            <Select
              options={numberOptions}
              onChange={(values) => setEmployeesNumber(values[0].value)}
              values={numberOptions.filter((option) => option.value === employeesNumber)}
              style={{
                width: 320,
                height: 36,
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgb(227, 227, 227)",
                paddingLeft: 8,
                paddingRight: 8,
                fontFamily: "Pretendard",
                fontWeight: 400,
                fontSize: 14,
                color: "rgb(70, 70, 70)",
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">기업구분</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: companyDivision.length > 0 ? "#3769FC" : "#FF7373" }} />
            </div>
            <Select
              options={corporateDivisionOptions}
              onChange={(values) => setCompanyDivision(values[0].value)}
              values={corporateDivisionOptions.filter((option) => option.value === companyDivision)}
              style={{
                width: 320,
                height: 36,
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgb(227, 227, 227)",
                paddingLeft: 8,
                paddingRight: 8,
                fontFamily: "Pretendard",
                fontWeight: 400,
                fontSize: 14,
                color: "rgb(70, 70, 70)",
              }}
            />
          </div>

          <InputShort
            label={"자본금"}
            placeholder={"숫자만 입력해주세요."}
            value={capital}
            onChange={setCapital}
            required
            requiredCondition={capital.length > 0 && isNumericString(capital)}
            unit={"원"}
            marginTop={"24px"}
          />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">투자 단계</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: investmentStage.length > 0 ? "#3769FC" : "#FF7373" }} />
            </div>
            <Select
              options={investmentPhaseOptions}
              onChange={(selectedOption) => setInvestmentStage(selectedOption[0].value)}
              values={investmentPhaseOptions.filter((option) => option.value === investmentStage)}
              style={{
                width: 320,
                height: 36,
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgb(227, 227, 227)",
                paddingLeft: 8,
                paddingRight: 8,
                fontFamily: "Pretendard",
                fontWeight: 400,
                fontSize: 14,
                color: "rgb(70, 70, 70)",
              }}
            />
          </div>

          <InputShort
            label={"투자액"}
            placeholder={"숫자만 입력해주세요."}
            value={investmentAmount}
            onChange={setInvestmentAmount}
            required
            requiredCondition={checkRequiredCondition(investmentAmount)}
            unit={"원"}
            marginTop={"24px"}
          />

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">주소</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: address.length > 0 ? "#3769FC" : "#FF7373" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "320px", marginBottom: "8px" }}>
              <input
                className="Input_Short_BG"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder={"주소를 입력해주세요."}
                name={"address"}
                style={{ width: "270px" }}
              />
              <button style={{ margin: "0px", padding: "0px", backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }} onClick={handleClick}>
                <Icon name={"Search"} size={"24"} color={"#AAAAAA"} />
              </button>
            </div>
            <input
              className="Input_Short_BG"
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
              placeholder={"상세 주소를 입력해주세요."}
              name={"detailAddress"}
            />
          </div>
        </div>
      </>
    );
  }
);

export default PLSignUpLinked3;
