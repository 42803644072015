const ChevronLeft = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8 11.9998L14.7 15.8998C14.8834 16.0831 14.975 16.3165 14.975 16.5998C14.975 16.8831 14.8834 17.1165 14.7 17.2998C14.5167 17.4831 14.2834 17.5748 14 17.5748C13.7167 17.5748 13.4834 17.4831 13.3 17.2998L8.70005 12.6998C8.60005 12.5998 8.52922 12.4915 8.48755 12.3748C8.44588 12.2581 8.42505 12.1331 8.42505 11.9998C8.42505 11.8665 8.44588 11.7415 8.48755 11.6248C8.52922 11.5081 8.60005 11.3998 8.70005 11.2998L13.3 6.6998C13.4834 6.51647 13.7167 6.4248 14 6.4248C14.2834 6.4248 14.5167 6.51647 14.7 6.6998C14.8834 6.88314 14.975 7.11647 14.975 7.3998C14.975 7.68314 14.8834 7.91647 14.7 8.0998L10.8 11.9998Z"
        fill={color || "#AAAAAA"}
      />
    </svg>
  );
};

export default ChevronLeft;
