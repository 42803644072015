import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useRef } from "react";
import { observer } from "mobx-react";

const InputShort = observer(
  ({
    label,
    placeholder,
    name,
    value,
    onChange,
    required,
    requiredCondition,
    buttonLabel,
    buttonStatus,
    onClick,
    marginBottom,
    marginTop,
    readOnly,
    type,
    onClickFile,
    unit,
    year,
    setYear,
    month,
    setMonth,
    day,
    setDay,
  }) => {
    const licenseInput = useRef();
    const sealImageInput = useRef();

    const onClickLicenseUpload = async () => {
      licenseInput.current.click();
    };

    const onClickSealUpload = async () => {
      sealImageInput.current.click();
    };

    const handleChange = (type, newValue) => {
      if (type === "year") {
        setYear(newValue);
      } else if (type === "month") {
        setMonth(newValue);
      } else if (type === "day") {
        setDay(newValue);
      }
    };

    return (
      <div className="Input_CT" style={{ marginBottom: marginBottom ? marginBottom : "24px", marginTop: marginTop ? marginTop : "0px" }}>
        {label && (
          <div className="Input_Label_CT">
            <div className="Input_Label">{label}</div>
            {required && <div className="Input_Label_Required_Dot" style={{ backgroundColor: requiredCondition ? "#3769FC" : "#FF7373" }} />}
          </div>
        )}
        {type !== "date" ? (
          <div className="Input_Short_Row_CT">
            <input
              className="Input_Short_BG"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={placeholder}
              name={name}
              style={{ width: buttonLabel ? "218px" : unit ? "280px" : "302px" }}
              readOnly={readOnly}
              type={type && type !== "licenseFile" ? type : "text"}
            />
            {buttonLabel && type !== "licenseFile" && type !== "sealFile" && (
              <button
                className={buttonStatus === "disabled" ? "Input_Short_Button_Disabled" : buttonStatus === "CTA" ? "Input_Short_Button" : "Input_Short_Button"}
                onClick={onClick}
              >
                {buttonLabel}
              </button>
            )}
            {buttonLabel && type === "licenseFile" && (
              <>
                <input accept=".pdf" type="file" onChange={(e) => onClickFile(e)} className="Display_None" ref={licenseInput} name={`${name}Input`} />
                <button
                  className={buttonStatus === "disabled" ? "Input_Short_Button_Disabled" : buttonStatus === "CTA" ? "Input_Short_Button" : "Input_Short_Button"}
                  onClick={onClickLicenseUpload}
                  type="button"
                >
                  {buttonLabel}
                </button>
              </>
            )}
            {buttonLabel && type === "sealFile" && (
              <>
                <input accept=".png" type="file" onChange={(e) => onClickFile(e)} className="Display_None" ref={sealImageInput} name={`${name}Input`} />
                <button
                  className={buttonStatus === "disabled" ? "Input_Short_Button_Disabled" : buttonStatus === "CTA" ? "Input_Short_Button" : "Input_Short_Button"}
                  onClick={onClickSealUpload}
                  type="button"
                >
                  {buttonLabel}
                </button>
              </>
            )}
            {unit && <div className="InputShort_Unit">{unit}</div>}
          </div>
        ) : (
          <div className="Input_Short_Row_CT">
            <input
              className="Input_Short_BG"
              value={year}
              onChange={(e) => handleChange("year", e.target.value)}
              placeholder={"YYYY"}
              name={`${name}-year`}
              style={{ width: "40px" }}
              maxLength={4}
            />
            <div className="InputShort_Unit" style={{ marginRight: "12px" }}>
              년
            </div>
            <input
              className="Input_Short_BG"
              value={month}
              onChange={(e) => handleChange("month", e.target.value)}
              placeholder={"MM"}
              name={`${name}-month`}
              style={{ width: "24px" }}
              maxLength={2}
            />
            <div className="InputShort_Unit" style={{ marginRight: "12px" }}>
              월
            </div>
            <input
              className="Input_Short_BG"
              value={day}
              onChange={(e) => handleChange("day", e.target.value)}
              placeholder={"DD"}
              name={`${name}-day`}
              style={{ width: "24px" }}
              maxLength={2}
            />
            <div className="InputShort_Unit">일</div>
          </div>
        )}
      </div>
    );
  }
);

export default InputShort;
