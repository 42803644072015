import { useEffect } from "react";
import TermList from "../Components/TermList";
import { observer } from "mobx-react";
import RegistrationTypeTitle from "../Components/RegistrationTypeTitle";
import axios from "axios";
import { gloablAPI } from "../Utils/globalAPI";

const PLLinkedWebDetails3 = observer(
  ({
    RegistrationTypeTitleData,
    isPlusRadioAllCheck,
    isPlusRadio1Check,
    isPlusRadio2Check,
    isPlusRadio3Check,
    isPlusRadio4Check,
    isPlusRadio5Check,
    termsPlusDone,
    setIsPlusRadioAllCheck,
    setIsPlusRadio1Check,
    setIsPlusRadio2Check,
    setIsPlusRadio3Check,
    setIsPlusRadio4Check,
    setIsPlusRadio5Check,
    setTermsPlusDone,
    setSignin2,
    setSignin3,
    setSignin4,
    setIsLoading,
    companyIdEmail,
    foundationDate,
    companyDivision,
    capital,
    investmentStage,
    investmentAmount,
    address,
    detailAddress,
    token,
  }) => {
    const checkAllPlusHandler = () => {
      if (!isPlusRadioAllCheck) {
        setIsPlusRadio1Check(true);
        setIsPlusRadio2Check(true);
        setIsPlusRadio3Check(true);
        setIsPlusRadio4Check(true);
        setIsPlusRadio5Check(true);
      } else {
        setIsPlusRadio1Check(false);
        setIsPlusRadio2Check(false);
        setIsPlusRadio3Check(false);
        setIsPlusRadio4Check(false);
        setIsPlusRadio5Check(false);
      }
    };

    useEffect(() => {
      if (isPlusRadio1Check && isPlusRadio2Check && isPlusRadio3Check) {
        setTermsPlusDone(true);
      } else {
        setTermsPlusDone(false);
      }

      if (!isPlusRadio1Check || !isPlusRadio2Check || !isPlusRadio3Check || !isPlusRadio4Check || !isPlusRadio5Check) {
        setIsPlusRadioAllCheck(false);
      }
    }, [isPlusRadio1Check, isPlusRadio2Check, isPlusRadio3Check, isPlusRadio4Check, isPlusRadio5Check, setIsPlusRadioAllCheck, setTermsPlusDone]);

    const privacyPolicy = "https://certifie-privacy-policy.vercel.app";
    const termsOfUse = "https://certifie-terms-of-use.vercel.app";

    const handleSigninComplete = async () => {
      if (!termsPlusDone) {
        alert("필수 약관에 동의해주세요.");
      } else {
        setIsLoading(true);

        //   amplitude.init("9fa2d56b8be8c76c4e7a38a6bb76da6f", companyIdEmail, {
        // defaultTracking: false,
        //   });

        const certedPlusTermsData = { emailTerm: isPlusRadio4Check, snsTerm: isPlusRadio5Check };
        const data = {
          establishmentDate: foundationDate,
          corporateDivision: companyDivision,
          capital: capital,
          investmentStage: investmentStage,
          investment: investmentAmount,
          address: detailAddress ? `${address}, ${detailAddress}` : address,
          certedPlusTerms: certedPlusTermsData,
        };

        try {
          const endPoint = `${gloablAPI}/product/member/assignment/member`;
          const response = await axios.post(endPoint, {
            data: data,
            token: token,
            email: companyIdEmail,
          });

          //   amplitude.track("회원가입5 - 담당자 정보 등록 완료", {
          //     담당자명: managerName,
          //     담당자번호: cleanedTelNumber,
          //     담당자포지션: etcManagerPosition.length > 0 ? etcManagerPosition : managerPosition[0].label,
          //   });
          //   amplitude.track("회원가입5 - 회원가입 완료 모달 팝업");
          if (response.data.status === "투자사 연동 성공") {
            setIsLoading(false);
            setSignin3(false);
            setSignin4(true);
          } else if (response.data.status === "투자사 연동 실패") {
            alert(`${response.data.message}\n다시 한번 시도해주세요.`);
            setIsLoading(false);
          } else {
            alert("써티드 회원가입 및 써티드 플러스 연동에 실패하였습니다.\n다시 한번 시도해주세요.");
            setIsLoading(false);
          }
        } catch (error) {
          console.log("Resist Company error", error);
          alert("써티드 회원가입 및 써티드 플러스 연동 통신에 실패하였습니다.\n다시 한번 시도해주세요.");
          setIsLoading(false);
        }
      }
    };

    const prevOnClick = () => {
      setSignin3(false);
      setSignin2(true);
    };

    return (
      <>
        <RegistrationTypeTitle
          type={"integrate"}
          info={RegistrationTypeTitleData[2]}
          nextOnClick={handleSigninComplete}
          previousOnClick={prevOnClick}
          isProgressNext={termsPlusDone}
        />

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="Input_Label_CT">
            <div className="Input_Label">써티드 플러스 연동 약관</div>
            <div className="Input_Label_Required_Dot" style={{ backgroundColor: termsPlusDone ? "#3769FC" : "#FF7373" }} />
          </div>
          <div
            className="TermLists_BG"
            style={{
              justifyContent: "start",
              width: 414,
              borderRadius: 8,
              paddingLeft: 12,
              paddingRight: 12,
              borderColor: "#AAAAAA",
              marginBottom: "24px",
            }}
          >
            <div
              style={{
                width: 412,
              }}
            >
              <TermList
                bodyText={"전체 동의"}
                twoLine
                width={412}
                towLineFontSize={14}
                blue
                isCheckBox={isPlusRadioAllCheck}
                setIsCheckBox={setIsPlusRadioAllCheck}
                checkAll
                checkAllHandler={checkAllPlusHandler}
              />

              <div
                className="TermLists_Line"
                style={{
                  marginBottom: 8,
                  width: 412,
                }}
              />

              <TermList
                width={412}
                required
                requiredColor={"#3769FC"}
                bodyFontSize={14}
                requiredFontSize={14}
                bodyText={"만 15세 이상입니다"}
                isCheckBox={isPlusRadio1Check}
                setIsCheckBox={setIsPlusRadio1Check}
              />
              <TermList
                required
                width={412}
                requiredColor={"#3769FC"}
                bodyFontSize={14}
                requiredFontSize={14}
                bodyText={"이용약관 동의"}
                detail
                detailLink={termsOfUse}
                isCheckBox={isPlusRadio2Check}
                setIsCheckBox={setIsPlusRadio2Check}
              />
              <div
                style={{
                  marginBottom: 8,
                }}
              >
                <TermList
                  required
                  width={412}
                  requiredColor={"#3769FC"}
                  bodyText={"개인정보 수집 및 이용 동의"}
                  bodyFontSize={14}
                  requiredFontSize={14}
                  detail
                  detailLink={privacyPolicy}
                  isCheckBox={isPlusRadio3Check}
                  setIsCheckBox={setIsPlusRadio3Check}
                />
              </div>
              <div
                className="TermLists_Line"
                style={{
                  marginBottom: 8,
                  width: 412,
                }}
              />

              <TermList
                optional
                width={412}
                bodyText={"이메일 뉴스레터 구독"}
                bodyFontSize={14}
                requiredFontSize={14}
                detail
                detailText={"이메일 수신 동의"}
                detailLink={privacyPolicy}
                isCheckBox={isPlusRadio4Check}
                setIsCheckBox={setIsPlusRadio4Check}
              />

              <TermList
                optional
                width={412}
                bodyText={"HR 이벤트, 성과관리 정보 수신"}
                bodyFontSize={14}
                requiredFontSize={14}
                detail
                detailText={"SMS 수신 동의"}
                detailLink={privacyPolicy}
                isCheckBox={isPlusRadio5Check}
                setIsCheckBox={setIsPlusRadio5Check}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default PLLinkedWebDetails3;
