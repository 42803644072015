const AddDomain = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V5C2 4.45 2.19583 3.97917 2.5875 3.5875C2.97917 3.19583 3.45 3 4 3H10C10.55 3 11.0208 3.19583 11.4125 3.5875C11.8042 3.97917 12 4.45 12 5V7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V14C22 14.2833 21.9042 14.5208 21.7125 14.7125C21.5208 14.9042 21.2833 15 21 15C20.7167 15 20.4792 14.9042 20.2875 14.7125C20.0958 14.5208 20 14.2833 20 14V9H12V11H14V13H12V15H14V17H12V19H15C15.2833 19 15.5208 19.0958 15.7125 19.2875C15.9042 19.4792 16 19.7167 16 20C16 20.2833 15.9042 20.5208 15.7125 20.7125C15.5208 20.9042 15.2833 21 15 21H4ZM4 19H6V17H4V19ZM4 15H6V13H4V15ZM4 11H6V9H4V11ZM4 7H6V5H4V7ZM8 19H10V17H8V19ZM8 15H10V13H8V15ZM8 11H10V9H8V11ZM8 7H10V5H8V7ZM21 23C20.7167 23 20.4792 22.9042 20.2875 22.7125C20.0958 22.5208 20 22.2833 20 22V21H19C18.7167 21 18.4792 20.9042 18.2875 20.7125C18.0958 20.5208 18 20.2833 18 20C18 19.7167 18.0958 19.4792 18.2875 19.2875C18.4792 19.0958 18.7167 19 19 19H20V18C20 17.7167 20.0958 17.4792 20.2875 17.2875C20.4792 17.0958 20.7167 17 21 17C21.2833 17 21.5208 17.0958 21.7125 17.2875C21.9042 17.4792 22 17.7167 22 18V19H23C23.2833 19 23.5208 19.0958 23.7125 19.2875C23.9042 19.4792 24 19.7167 24 20C24 20.2833 23.9042 20.5208 23.7125 20.7125C23.5208 20.9042 23.2833 21 23 21H22V22C22 22.2833 21.9042 22.5208 21.7125 22.7125C21.5208 22.9042 21.2833 23 21 23ZM16 13V11H18V13H16ZM16 17V15H18V17H16Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default AddDomain;
