const AddCreditCard = ({ size, color }) => {
  return (
    <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V11C22 11.2833 21.9042 11.5208 21.7125 11.7125C21.5208 11.9042 21.2833 12 21 12H4V18H13C13.2833 18 13.5208 18.0958 13.7125 18.2875C13.9042 18.4792 14 18.7167 14 19C14 19.2833 13.9042 19.5208 13.7125 19.7125C13.5208 19.9042 13.2833 20 13 20H4ZM4 8H20V6H4V8ZM19 19H17C16.7167 19 16.4792 18.9042 16.2875 18.7125C16.0958 18.5208 16 18.2833 16 18C16 17.7167 16.0958 17.4792 16.2875 17.2875C16.4792 17.0958 16.7167 17 17 17H19V15C19 14.7167 19.0958 14.4792 19.2875 14.2875C19.4792 14.0958 19.7167 14 20 14C20.2833 14 20.5208 14.0958 20.7125 14.2875C20.9042 14.4792 21 14.7167 21 15V17H23C23.2833 17 23.5208 17.0958 23.7125 17.2875C23.9042 17.4792 24 17.7167 24 18C24 18.2833 23.9042 18.5208 23.7125 18.7125C23.5208 18.9042 23.2833 19 23 19H21V21C21 21.2833 20.9042 21.5208 20.7125 21.7125C20.5208 21.9042 20.2833 22 20 22C19.7167 22 19.4792 21.9042 19.2875 21.7125C19.0958 21.5208 19 21.2833 19 21V19Z"
        fill={color || "#464646"}
      />
    </svg>
  );
};

export default AddCreditCard;
